<template>
  <el-main>
    <el-form class="set-order" ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item class="item" label="分类名称：" prop="name">
        <el-input v-model="form.name"></el-input>
        <span class="sp2">分类名称建议12符号/6字以内</span>
      </el-form-item>
      <el-form-item class="item" label="上级分类：">
        <el-select style="width: 245px; margin-right: 10px" v-model="form.oneTypeId" :disabled="disabled_type" clearable placeholder="一级" @change="getSecondType">
          <el-option v-for="item in goodsClass" :key="item.id" :disabled="disabled_type" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select style="width: 245px" v-model="form.secondTypeId" :disabled="disabled_type" clearable placeholder="二级">
          <el-option v-for="item in secondType" :key="item.id" :disabled="disabled_type" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <span class="sp2">不选择则默认为一级分类</span>
      </el-form-item>
      <el-form-item class="item" label="组内权重：">
        <el-input type="number" :min="0" placeholder="请输入内容" v-model="form.sort"></el-input>
        <span class="sp2">数字越大，排名越靠前；如为空，排序顺序为添加时间</span>
      </el-form-item>
      <el-form-item class="item" label="类型：" prop="type">
        <el-select v-model="form.type">
          <el-option label="PE制品" :value="1"></el-option>
          <el-option label="生物塑料" :value="2"></el-option>
          <el-option label="特种塑料" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="item type-img" label="分类图片 ：">
        <div>
          <ReadyUploadSource @getSource="getTypeImg" :path="form.picture" @removeThis="() => (form.picture = '')"></ReadyUploadSource>
        </div>
      </el-form-item>
      <el-form-item class="item" label="是否显示：">
        <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <Preservation @preservation="onSubmit('form')">
        <router-link to="/gateway/goods/goodsType" slot="return" v-if="form.id" style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
      </Preservation>
    </el-form>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
export default {
  components: {
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      form: {
        id: 0,
        is_show: 1,
        name: '',
        picture: '',
        sort: '',
        parent_id: [],
        type: '',
        oneTypeId: '',
        secondTypeId: '',
      },
      disabled_type: !1,
      secondType: [],
      rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      },
    };
  },
  computed: {
    goodsClass() {
      let data = this.$store.getters.goodsTypeList;
      if (this.form.id && this.form.parent_id) {
        for (let i in data) {
          for (let y in data[i].children) {
            if (data[i].children[y].id == this.form.id) {
              this.form.oneTypeId = data[i].id;
            }
            for (let k in data[i].children[y].children) {
              if (data[i].children[y].children[k].id == this.form.id) {
                this.form.secondTypeId = data[i].children[k].id;
              }
            }
          }
        }
      }
      
      for (let i in data) {
        for (let y in data[i].children) delete data[i].children[y].children;
      }
      if (this.form.parent_id) {
        let val = data.find(item => item.id == this.form.oneTypeId);
        if(val){
          this.secondType = val.children;
        }
      }
      return data;
    },
  },
  created() {
    if (this.$route.query.info) {
      let data = JSON.parse(this.$route.query.info);
      if (data.grade == '一级') this.disabled_type = !0;
      this.form.is_show = data.is_show;
      this.form.name = data.name;
      this.form.sort = data.sort;
      this.form.picture = data.picture;
      this.form.parent_id = data.parent_id ? data.parent_id : '';
      this.form.type = data.type;
      this.form.id = data.id;
    }
    this.$store.dispatch('goods/getTypeList', { industryName });
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          if (this.form.oneTypeId) this.form.parent_id = this.form.oneTypeId;
          if (this.form.secondTypeId) this.form.parent_id = this.form.secondTypeId;
          let data = {
            is_show: this.form.is_show,
            picture: this.form.picture,
            sort: this.form.sort,
            parent_id: this.form.parent_id || 0,
            name: this.form.name,
            type: this.form.type,
          };
          if (this.form.id) data.id = this.form.id;
          let url = data.id ? industryName + this.$api.goods.editType : industryName + this.$api.goods.addType;
          this.$axios.post(url, data).then(res => {
            if (res.code == 0) {
              this.$message.success(`${this.form.id ? '编辑成功' : '添加成功'}`);
              this.$router.push('/gateway/goods/goodsType');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getSecondType(val) {
      let data = this.goodsClass.find(item => item.id == val);
      this.secondType = data._child || [];
    },
    getTypeImg(val) {
      this.form.picture = val.path;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  padding: 50px 0 0 100px;
  background: #fff;
  .set-order {
    width: 1000px;
    .item {
      .el-input,
      .el-select,
      .el-cascader {
        width: 500px;
      }
      .sp2 {
        margin-left: 20px;
        color: #999;
      }
    }
    .type-img {
      align-items: flex-start;
      span {
        margin-top: 10px;
        margin-left: 0 !important;
      }
    }
  }
}
</style>
